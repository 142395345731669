import React, { Component, useState } from "react";
import "./styles.css";

const CardList = (props) => (
  <div className="container">
    {props.profiles.map((profile) => (
      <Card key={profile.shortUrl} {...profile} />
    ))}
  </div>
);

class Card extends Component {
  render() {
    const profile = this.props;
    return (
      <div className="card">
        <div className="info">
          <div className="longUrl">{profile.longUrl}</div>
          <div className="shortUrl">
            <a href={"https://" + profile.longUrl} target="_blank" rel="noopener noreferrer">
              {profile.shortUrl}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const BackgroundChanger = () => {
  const [isImage1, setIsImage1] = useState(true);

  const handleChangeBackground = () => {
    if (isImage1) {
      document.body.style.backgroundImage = "url('/2.jpg')";
    } else {
      document.body.style.backgroundImage = "url('/1.jpg')";
    }
    setIsImage1(!isImage1);
  };

  return (
    <button id="changeBtn" className={isImage1 ? 'light' : 'dark'} onClick={handleChangeBackground}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
        <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
      </svg>
    </button>
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    this.outputRef = React.createRef();
    this.state = {
      profiles: JSON.parse(localStorage.getItem("profiles")) || [],
      longUrl: ""
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const shortUrl = require("shortid").generate();
    this.setState((prevState) => ({
      profiles: [...prevState.profiles, { longUrl: prevState.longUrl, shortUrl }],
      longUrl: ""
    }));

    // Store data in local storage
    const profiles = JSON.parse(localStorage.getItem("profiles")) || [];
    profiles.push({ longUrl: this.state.longUrl, shortUrl });
    localStorage.setItem("profiles", JSON.stringify(profiles));
  };

  componentDidMount() {
    this.setInputWidth();
  }

  setInputWidth = () => {
    const outputWidth = this.outputRef.current.offsetWidth;
    this.inputRef.style.width = outputWidth + "px";
  };

  render() {
    return (
      <div>
        <BackgroundChanger />
        <h1 className="header">URL Shortener</h1>
        <div className="form-container">
          <form onSubmit={this.handleSubmit}>
            <input
              ref={(input) => (this.inputRef = input)}
              type="text"
              value={this.state.longUrl}
              onChange={(event) => this.setState({ longUrl: event.target.value })}
              placeholder="enter the url to shorten"
              required
            />
            <button>Shorten</button>
          </form>
          <div ref={this.outputRef} style={{ visibility: "hidden", whiteSpace: "nowrap" }}>
            {/* Output content, invisible but will determine width */}
            https://example.com/short-url
          </div>
        </div>
        <CardList profiles={this.state.profiles} />
      </div>
    );
  }
}

export default App;



